<template>
  <div class="section1">
    <div class="text-section1" data-aos="zoom-in" data-aos-duration="1000">
      <h1 class="text-center">Bonjour !</h1>
      <h2 class="text-center">Je suis <span>Sylvain</span>,</h2>
      <h2 class="text-center"><span>Testeur Logiciels</span></h2>
    </div>
    <div class="reseauxsoc-section1">
      <img src="@/assets/images/logo-avatar/photo-rond.jpg" />

      <div class="reseaux">
        <div class="contact contactMail" @click="mailTo">
          <fontAwesomeIcon
            :icon="['fas', 'envelope']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactFace" @click="faceGo">
          <fontAwesomeIcon
            :icon="['fab', 'facebook-f']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactLinked" @click="linkGo">
          <fontAwesomeIcon
            :icon="['fab', 'linkedin-in']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactTwitt" @click="twittGo">
          <fontAwesomeIcon
            :icon="['fab', 'twitter']"
            class="fa-2x menuColor text-white"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div
          class="h6 mt-5 border-top border-bottom pt-3 pb-3 pt-lg-5 pb-lg-5 text-center text-white"
        >
          <span
            >Après avoir travaillé dans la paie/RH dans différents cabinets
            comptables,
          </span>
          <br />
          <span
            >j'ai saisi l'opportunité d'une reconversion pour devenir testeur
            logiciels.</span
          ><br />
          <router-link
            class="btn btn-dark mt-2 float-lg-right mb-lg-5 btn-sm"
            :to="{ name: 'A-Propos' }"
          >
            En savoir plus
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  methods: {
    faceGo() {
      window.open("https://www.facebook.com/sylvain.salliou.79", "_blank");
    },
    linkGo() {
      window.open(
        "https://www.linkedin.com/in/sylvain-salliou-4424b5113",
        "_blank"
      );
    },
    twittGo() {
      window.open("https://twitter.com/SalliouSylvain", "_blank");
    },
    mailTo() {
      window.open("mailto:ssalliou.dev@gmail.com", "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/intro.scss";
</style>
