import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/a-propos",
    name: "A-Propos",
    component: () => import("@/views/A-propos.vue")
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("@/views/Portfolio.vue")
  },
  {
    path: "/lettresmissions",
    name: "Lettresmissions",
    component: () => import("@/components/portfolio/Lettresmissions.vue")
  },
  {
    path: "/chefpro",
    name: "ChefPro",
    component: () => import("@/components/portfolio/ChefPro.vue")
  },
  {
    path: "/ecolidaire",
    name: "Ecolidaire",
    component: () => import("@/components/portfolio/Ecolidaire.vue")
  },
  {
    path: "/kuizu",
    name: "Kuizu",
    component: () => import("@/components/portfolio/Kuizu.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
