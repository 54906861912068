<template>
  <div class="home">
    <Navbar />
    <Intro />
    <div class="h2 text-white bg-dark">
      Quelques projets sur lesquels j'ai travaillé
    </div>
    <Projets />
    <p>
      <router-link
        class="btn btn-dark mt-2 float-lg-center mb-lg-5 btn-sm"
        :to="{ name: 'Portfolio' }"
      >
        Tous mes projets

        <fontAwesomeIcon :icon="['fas', 'arrow-right']" class=" menuColor" />
      </router-link>
    </p>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Intro from "@/components/Intro.vue";
import Projets from "@/components/portfolio/Projets.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Intro,
    Projets,
    Footer
  }
};
</script>
