import "@babel/polyfill";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import BootstrapVue from "bootstrap-vue";
import Bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";

library.add(fab, fas);
Vue.config.productionTip = false;

Vue.component("fontAwesomeIcon", FontAwesomeIcon);
Vue.use(BootstrapVue);

new Vue({
  created() {
    AOS.init();
  },
  router,
  Bootstrap,
  BootstrapVue,
  render: h => h(App)
}).$mount("#app");
