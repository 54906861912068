<template>
  <div class="section4">
    <div
      class="project"
      data-aos="zoom-in"
      data-aos-duration="1000"
      @click="seeIt = !seeIt"
    >
      <div class="project-text-box" :style="[seeIt ? { opacity: 0.9 } : null]">
        <h2 class="project-text">Lettres de missions</h2>
        <button @click="lettresmissionsPage">En savoir +</button>
      </div>
    </div>

    <div
      class="project2"
      data-aos="zoom-in"
      data-aos-duration="1000"
      @click="seeIt1 = !seeIt1"
    >
      <div class="project-text-box" :style="[seeIt1 ? { opacity: 0.9 } : null]">
        <h2 class="project-text">Refonte d'un site</h2>
        <button @click="chefProPage">En savoir +</button>
      </div>
    </div>

    <div
      class="project3"
      data-aos="zoom-in"
      data-aos-duration="1000"
      @click="seeIt1 = !seeIt1"
    >
      <div class="project-text-box" :style="[seeIt1 ? { opacity: 0.9 } : null]">
        <h2 class="project-text">Ecolidaire</h2>
        <button @click="ecolidairePage">En savoir +</button>
      </div>
    </div>

    <div
      class="project4"
      data-aos="zoom-in"
      data-aos-duration="1000"
      @click="seeIt2 = !seeIt2"
    >
      <div class="project-text-box" :style="[seeIt2 ? { opacity: 0.9 } : null]">
        <h2 class="project-text">Kuizu</h2>
        <button @click="kuizuPage">En savoir +</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projets",

  data() {
    return {
      seeIt: false,
      seeIt1: false,
      seeIt2: false
    };
  },
  methods: {
    lettresmissionsPage() {
      this.$router.push("lettresmissions");
    },
    chefProPage() {
      this.$router.push("chefPro");
    },
    ecolidairePage() {
      this.$router.push("ecolidaire");
    },
    kuizuPage() {
      this.$router.push("kuizu");
    },
    visible1() {
      this.seeIt1 = true;
    },
    visible2() {
      this.seeIt2 = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/projets.scss";
</style>
