<template>
  <nav class="navbar navbar-expand-lg bg-dark" id="mainNav">
    <div class="container">
      <div class="row">
        <router-link :to="{ name: 'Home' }">
          <img
            width="100px"
            height="100px"
            class="d-flex align-items-center my-2 my-lg-0 ml-5 ml-lg-0 mr-lg-auto text-white text-decoration-none rounded"
            src="@/assets/images/logo-avatar/sylvain-salliou-logo.svg"
          />
        </router-link>
      </div>
      <button
        class="menuBtn navbar-toggler font-weight-bold text-white border-white rounded"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Menu <fontAwesomeIcon :icon="['fas', 'align-justify']" />
      </button>

      <div
        class="collapse navbar-collapse justify-content-right"
        id="navbarSupportedContent"
      >
        <ul
          class="navbar ml-auto nav col-12 col-lg-auto my-2 my-md-0 text-small"
        >
          <li class="nav-item mx-0 mx-lg-1">
            <router-link
              class="nav-link text-white py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              :to="{ name: 'Home' }"
            >
              <fontAwesomeIcon
                :icon="['fas', 'home']"
                class="fa-2x menuColor"
              />
              <span class="navSize ml-1">Accueil</span>
            </router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link
              class="nav-link text-white py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              :to="{ name: 'A-Propos' }"
            >
              <fontAwesomeIcon
                :icon="['fas', 'id-card']"
                class="fa-2x menuColor"
              />
              <span class="navSize ml-1">À propos</span>
            </router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link
              class="nav-link text-white py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              :to="{ name: 'Portfolio' }"
            >
              <fontAwesomeIcon
                :icon="['fas', 'folder-open']"
                class="fa-2x menuColor"
              />
              <span class="navSize ml-1">Portfolio</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  components: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/navbar.scss";
@media (min-width: 760px) {
  .navSize {
    font-size: 2rem;
  } /*1rem = 16px*/
}
</style>
