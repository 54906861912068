<template>
  <div class="section5">
    <div>
      <h2>
        <div>
          © {{ yearStart }} -
          {{ new Date().getFullYear() }}
        </div>
        <div>Made with ❤️ by Sylvain</div>
      </h2>
      <div class="call">
        <div class="contact contactMail" @click="mailTo">
          <fontAwesomeIcon
            :icon="['fas', 'envelope']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactFace" @click="faceGo">
          <fontAwesomeIcon
            :icon="['fab', 'facebook-f']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactLinked" @click="linkGo">
          <fontAwesomeIcon
            :icon="['fab', 'linkedin-in']"
            class="fa-2x menuColor text-white"
          />
        </div>
        <div class="contact contactTwitt" @click="twittGo">
          <fontAwesomeIcon
            :icon="['fab', 'twitter']"
            class="fa-2x menuColor text-white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Other",
  data() {
    return {
      yearStart: 2020,
      modalShow: false,
    };
  },
  methods: {
    faceGo() {
      window.open("https://www.facebook.com/sylvain.salliou.79", "_blank");
    },
    linkGo() {
      window.open(
        "https://www.linkedin.com/in/sylvain-salliou-4424b5113",
        "_blank"
      );
    },
    twittGo() {
      window.open("https://twitter.com/SalliouSylvain", "_blank");
    },
    mailTo() {
      window.open("mailto:sylvain.salliou@gmail.com", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/footer.scss";
</style>
